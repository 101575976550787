// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MailkickSubscribeRequest from "./requests/MailkickSubscribeRequest.res.js";
import * as MailkickUnsubscribeRequest from "./requests/MailkickUnsubscribeRequest.res.js";

var subscribe = MailkickSubscribeRequest.exec;

var unsubscribe = MailkickUnsubscribeRequest.exec;

export {
  subscribe ,
  unsubscribe ,
}
/* MailkickSubscribeRequest Not a pure module */
