// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H4 from "../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as TopNavbar from "../top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_MailkickSubscription from "../../../api/mailkick-subscriptions/Api_MailkickSubscription.res.js";
import * as UserDashboardNotificationsScss from "./UserDashboardNotifications.scss";

var css = UserDashboardNotificationsScss;

function UserDashboardNotifications$default(props) {
  var userId = props.userId;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(TopNavbar.make, {
                      selectedCategory: "Notifications",
                      userRole: "Admin"
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(props.subscriptionsList, (function (index, list) {
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(H4.make, {
                                                  className: css.subscriptionsTitle,
                                                  children: list.title
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: list.description,
                                                  className: css.subscriptionsExplanation
                                                }),
                                            Belt_Array.mapWithIndex(Belt_Array.range(0, list.rows - 1 | 0), (function (index, param) {
                                                    return JsxRuntime.jsx("div", {
                                                                children: Belt_Array.map(Belt_Array.slice(list.subscriptions, Math.imul(index, 3), 3), (function (subscription) {
                                                                        var match = React.useState(function () {
                                                                              return subscription.subscribed;
                                                                            });
                                                                        var setSubscribed = match[1];
                                                                        var subscribed = match[0];
                                                                        return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                                    id: "subscription--" + subscription.list,
                                                                                    size: "SM",
                                                                                    checked: subscribed,
                                                                                    containerClassName: css.subscriptionOption,
                                                                                    onChange: (function (param) {
                                                                                        if (subscribed) {
                                                                                          return $$Promise.wait(Api_MailkickSubscription.unsubscribe(userId, subscription.list), (function (x) {
                                                                                                        if (x.TAG === "Ok") {
                                                                                                          return setSubscribed(function (_prev) {
                                                                                                                      return false;
                                                                                                                    });
                                                                                                        } else {
                                                                                                          return SentryLogger.error1({
                                                                                                                      rootModule: "UserDashboardNotifications",
                                                                                                                      subModulePath: /* [] */0,
                                                                                                                      value: "default",
                                                                                                                      fullPath: "UserDashboardNotifications.default"
                                                                                                                    }, "MailkickSubscription::Update::Error", [
                                                                                                                      "Error",
                                                                                                                      x._0
                                                                                                                    ]);
                                                                                                        }
                                                                                                      }));
                                                                                        } else {
                                                                                          return $$Promise.wait(Api_MailkickSubscription.subscribe(userId, subscription.list), (function (x) {
                                                                                                        if (x.TAG === "Ok") {
                                                                                                          return setSubscribed(function (_prev) {
                                                                                                                      return true;
                                                                                                                    });
                                                                                                        } else {
                                                                                                          return SentryLogger.error1({
                                                                                                                      rootModule: "UserDashboardNotifications",
                                                                                                                      subModulePath: /* [] */0,
                                                                                                                      value: "default",
                                                                                                                      fullPath: "UserDashboardNotifications.default"
                                                                                                                    }, "MailkickSubscription::Update::Error", [
                                                                                                                      "Error",
                                                                                                                      x._0
                                                                                                                    ]);
                                                                                                        }
                                                                                                      }));
                                                                                        }
                                                                                      }),
                                                                                    children: JsxRuntime.jsx("div", {
                                                                                          children: subscription.name
                                                                                        })
                                                                                  }, subscription.list + "--" + String(index) + "--" + ID.toString(userId));
                                                                      })),
                                                                className: css.subscriptionsRow
                                                              }, "subscriptionsRow-" + String(index));
                                                  }))
                                          ],
                                          className: css.subscriptions
                                        }, list.title + "-" + String(index));
                            })),
                      className: css.subscriptionsContainer
                    })
              ],
              className: css.container
            });
}

var $$default = UserDashboardNotifications$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
