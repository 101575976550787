// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";

function exec(userId, list) {
  return Rest.$$fetch("/dashboard/users/" + ID.toString(userId) + "/unsubscribe?list=" + list, {
              NAME: "Patch",
              VAL: null
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
